.logo img {
    height: 35px;
    width: 35px;
}

.userpicbox {
    padding-top:15px;
    margin-left:-10px;
    
}
.userpic img {
    height: 40px;
    width: 40px;
    margin-left:22px;
    margin-top: 5px;
}


