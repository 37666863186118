@keyframes pulse-rainbow {
    0% {
        box-shadow: 0 0 0 1px hsla(195, 70%, 54%, 0.1);
    }
    25% {
        box-shadow: 0 0 0 3px hsla(205, 70%, 54%, 0.1);
    }
    50% {
        box-shadow: 0 0 0 1px hsla(215, 70%, 54%, 0.1);
    }
    75% {
        box-shadow: 0 0 0 3px hsla(225, 70%, 54%, 0.1);
    }
    100% {
        box-shadow: 0 0 0 1px hsla(195, 70%, 54%, 0.1);
    }
}

@keyframes rotate-gradient {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.upgradeBox {
    border: 1px rgba(110, 113, 122, 0.2) !important;
    border-style: solid !important;
    border-radius: 10px !important;
    margin-left: -5px;
    animation: pulse-rainbow 4s linear infinite;
    position: relative;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.87);
    background: white !important;
}

.upgradeBox::before, .upgradeBox::after {
    content: '';
    position: absolute;
    inset: -100%;
    background: linear-gradient(145deg,
        rgba(255, 255, 255, 0.97) 0%,
        rgba(255, 255, 255, 0.97) 50%,
        rgba(124, 95, 228, 0.13) 75%,  /* Purple #7C5FE4 */
        rgba(164, 0, 153, 0.12) 85%,   /* Pink #A40099 */
        rgba(93, 162, 0, 0.22) 100%    /* Green #5DA200 */
    );
    animation: rotate-gradient 28s linear infinite;
    filter: blur(30px);
    z-index: 0;
    pointer-events: none;
}

.upgradeBox::after {
    background: linear-gradient(-45deg,
        transparent 0%,
        transparent 60%,
        rgba(255, 105, 196, 0.12) 80%, /* Pink #FF69C4 */
        rgba(255, 199, 0, 0.12) 100%   /* Yellow #FFC700 */
    );
    animation: rotate-gradient 12s linear infinite reverse;
    opacity: 0.7;
}

.upgradeBox > * {
    position: relative;
    z-index: 1;
}