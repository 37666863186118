@keyframes pulse-blue {
    0% {
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }

    70% {
        box-shadow: 0 0 0 6px rgba(52, 172, 224, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
}
.reportTitle {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

}

.reportBox {
    border: 1px rgba(110, 113, 122, 0.2) !important;
    border-style: solid !important;
    border-radius: 10px !important;
    margin-left: -5px;
    height: 70px;
}

.reportBoxActive {
    border: 1px #6327E6 !important;
    box-shadow: rgba(210, 153, 250, 0.4) 0px 2px 8px !important;
    border-style: solid !important;
    border-radius: 10px !important;
    margin-left: -5px;
    height: 70px;
}

.reportBoxInProgress {
    border: 1px rgba(110, 113, 122, 0.2) !important;
    border-style: solid !important;
    border-radius: 10px !important;
    margin-left: -5px;
    height: 70px;
    animation: pulse-blue 2s infinite;
}


.reportBoxError {
    border: 1px rgba(255, 0, 0, 0.3) !important;
    box-shadow: rgba(255, 0, 0, 0.2) 0px 2px 8px !important;
    border-style: solid !important;
    border-radius: 10px !important;
    margin-left: -5px;
    height: 70px;
}

.uploadBox {
    background-color: rgba(110, 113, 122, 0.2) !important;
    border-style: solid !important;
    border-radius: 10px !important;
    margin-left: -5px;
    height: 70px;
}

.uploadBox-drag {
    background-color: rgba(110, 113, 122, 0) !important;
    border-style: solid !important;
    border-radius: 10px !important;
    margin-left: -5px;
    height: 70px;
    animation: pulse-blue 2s infinite;
}

.programOverview h4 {
    font-size: 24px;
    font-weight: 600 !important;
    letter-spacing: -0.25px;
    margin-top: 0;
}

.programOverview h6 {
    color: #9A98A2;
    font-weight: 600;
    margin-top: 8px;
    margin-bottom: 0px;
    letter-spacing: 0.5px;
    font-size: 12px;
}