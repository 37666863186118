.chartpreview-box {
    background-color: #f3f3f4;
    padding-bottom: 15px;
}

.dataFilterField {
    margin-bottom: 10px;
}
.titleFieldRow {
    padding: 10px;
    height: 50px;
    padding-left: 20px;
    margin-top: 10px;
    text-align: left;
    line-height: 50px;
    display: flex;
    align-items: center;
}

.top-box {
    height: auto;
    min-height: 290px;
    padding: 10px;
    margin-bottom: 10px;
}

.middle-box {
}

.bottom-box {
    margin-top: 10px;
}

.selectBox {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    height: 56px;
    padding-left: 20px;
    margin-top: 10px;
}

.board-container {
    height: 100vh;
    display: flex;
    position: relative;
}

.chart-type-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    width: 320px;
    height: 100vh;
    background: white;
    box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1);
    z-index: 1200;
    overflow-y: auto;
    transition: all 0.3s ease;
}

.chart-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
}

.react-grid-item {
    transition: none !important;
}

.react-grid-item.cssTransforms {
    transition: none !important;
}

.react-grid-item.resizing {
    transition: none !important;
}

.react-grid-item.react-draggable-dragging {
    transition: none !important;
}

.grid-item {
    background: #fff;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.grid-item:hover {
    box-shadow: none;
}

.react-resizable-handle {
    display: none !important;
}

.react-grid-item.react-grid-placeholder {
    background: #7c4dff;
    opacity: 0.2;
    border-radius: 4px;
}

.grid-item-wrapper {
    position: relative;
}

/* Hide the add button by default */
.grid-item-wrapper .empty-grid-item {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

/* Show the add button on hover */
.grid-item-wrapper:hover .empty-grid-item {
    opacity: 1;
}

.add-chart-overlay {
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
    z-index: 10;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid-item-wrapper:hover .add-chart-overlay {
    opacity: 1;
    pointer-events: auto;
}

.empty-grid-item {
    border: 2px dashed #ccc;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f8f8;
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    user-select: none;
    pointer-events: auto;
}

.empty-grid-item:hover {
    transform: scale(1.1);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.empty-grid-item.standalone {
    min-height: 200px;
}

.empty-grid-item.addon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #7c4dff;
    border: none;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.empty-grid-item.addon:hover {
    background: #6c3fff;
    transform: scale(1.1);
}

.empty-grid-item.addon .empty-grid-item-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty-grid-item.addon .MuiSvgIcon-root {
    font-size: 20px;
    margin: 0;
    color: white;
}

.empty-grid-item.standalone:hover {
    border-color: #7c4dff;
    background: #f3f0ff;
}

.empty-grid-item-content {
    text-align: center;
    color: #666;
}

.empty-grid-item-content .MuiSvgIcon-root {
    font-size: 2rem;
    margin-bottom: 8px;
    color: #7c4dff;
}

.react-grid-item.react-draggable {
    z-index: 1;
}

.empty-grid-item .react-resizable-handle,
.empty-grid-item .react-draggable-handle {
    display: none !important;
}

.react-grid-layout {
    max-width: 100% !important;
    overflow: hidden !important;
}

.layout {
    max-width: 100%;
}

.chart-option-card {
    transition: all 0.2s ease;
}

.chart-option-card:hover {
    transform: translateY(-2px);
}