.winAccordian {
    background: #F3F3F4 !important;
    margin-bottom:15px !important;
    border: 0px;
    box-shadow: none;
    border-radius: 10px !important;
}

.winAccordianExpanded {
    background: #ffffff;
    margin-bottom:15px;
    border: 1px #6327E6 !important;
    border-style: solid !important;
    box-shadow: rgba(210, 153, 250, 0.3) 0px 2px 8px !important;
    border-radius: 10px !important;
}

blockquote p {
    background: #F3F3F4;
    padding: 5px 5px 5px 5px ;
    border-radius: 10px !important;
}

.moduleSummaryText {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 20px;
}

.moduleStrongText {
    font-weight: bold;
}

.moduleSummaryTextQuote .moduleSummaryText {
    background: #F3F3F4 !important;
    border-radius: 10px !important;
    padding: 10px 10px 10px 10px;
}