
.bottom_buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.error-message-area {
    height: 40px;
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
}
