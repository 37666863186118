.questionBox {
    border: 2px rgba(0, 0, 0, 0.1) !important;
    border-style: solid !important;
    border-radius: 16px;
    width: 100%;    
    margin-bottom: 25px !important;
  }

  .questionType {
    border-radius: 16px;
  }