.engagement-box {
  min-height: 400px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}


.engagmentQuestionFilter {
  min-width: 300px;
}

.enagement-filter-chip {
  padding-right:5px;
}
.engagement-chart-container {
  flex-grow: 1;
  width: 100%;
  min-height: 300px;
}

.add-engagement-box {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
}

.add-engagement-box button {
  font-size: 1rem;
  padding: 10px 14px;
}

.engagements-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  max-height: 80%;
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 32px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modal-header {
  padding: 24px 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.modal-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 24px 32px;
}

/* .modal-content button {
  width: 86px;
} */

.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.report-container {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  field-sizing: content;
}

.scrollable-questions {
  display: flex;
  overflow-x: auto;
  padding: 8px 0;
  gap: 20px;
}

.scrollable-questions::-webkit-scrollbar {
  height: 4px;
}

.scrollable-questions::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,.2);
  border-radius: 3px;
}

.question-box {
  width: 270px;
  height: 84px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
}

.question-box.disabled {
  opacity: 0.5;
}


.question-content {
  flex: 1;
  display: flex;
  padding: 0 12px 2px 0;
}

.question-checkbox {
  position: absolute !important;
  top: 0px;
  right: 0px;
}

.question-checkbox .MuiSvgIcon-root {
  width: 15px;
  height: 15px;
}

.question-label {
  margin: 0;
  width: 100%;
}

.question-text {
  font-size: 0.875rem;
  line-height: 1.2;
  max-height: 5.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

