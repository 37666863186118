.breakdown-container {
    height: calc(100vh - 300px); /* Adjust this value as needed */
    overflow-y: auto;
    scroll-behavior: smooth;
}

.questions-container {
    height: calc(100vh - 300px); /* Adjust this value as needed */
    overflow-y: auto;
}


.scroll-container {
    padding-right: 2px;
}
.scroll-container::-webkit-scrollbar {
    background-color: rgb(0,0,0,0);
    width: 8px;
  }
  
  /* background of the scrollbar except button or resizer */
  .scroll-container::-webkit-scrollbar-track {
    background-color: rgb(0,0,0,0);

  }
  
  /* scrollbar itself */
  .scroll-container::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    padding-left: 10px;
  }
  
  /* set button(top and bottom of the scrollbar) */
  .scroll-container::-webkit-scrollbar-button {
    display:none;
  }

  /* Question list */


.activeQuestion {
  border: 1px #6327E6 !important;
  border-style: solid !important;
  box-shadow: rgba(210, 153, 250, 0.3) 0px 2px 8px !important;
}

.questionMapper {
  border: 1px rgba(0, 0, 0, 0.6) !important;
  border-style: solid !important;
  border-radius: 14px !important;
  width: 100%;

}
.questionMapperContent
{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}