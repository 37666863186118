@font-face {
  font-family: 'Jakarta';
  src: url('../fonts/jakarta/PlusJakartaSans-Regular.ttf') format('truetype');
}

.secondaryContent {
  color: #6B6876;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: left;
  
}

.rounded-box {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background-color: white;
}

:root {
  --color-background: #666666;

  /* layout */
  --layout-container-height: 100%;
  --layout-card-height: 672px;
  --layout-card-width: 1216px;
  --layout-content-margin: 0 8rem;
}
body {
  font-family: Jakarta, Arial, serif !important;
  font-weight: black;
}
/* media */

/* small-vert */
@media screen and (max-height: 672px) {
  :root {
    /* layout */
    --layout-container-height: unset;
  }
}

/* l-hor */
@media screen and (max-width: 1616px) {
  :root {
    /* layout */
    --layout-content-margin: 0 2rem;
  }
}

/* xs-hor */
@media screen and (max-width: 768px) {
  :root {
    --color-background: #fff;

    /* layout */
    --layout-container-height: auto;
    --layout-card-height: 100vh;
    --layout-card-width: 100%;
    --layout-content-margin: 0;
  }
}

/* /media */

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  padding: 0 !important;
}

a:hover {
  background: none !important;
}

button {
  height: fit-content;
}


.radix-themes {
  --default-max-width: 18px;
}



/* Program list */

.programOverview {
  border-radius: 8px !important;
  margin-left: -5px;
}



.programOverview h4 {
  font-size: 24px;
  font-weight: 600 !important;
  letter-spacing: -0.25px;
  margin-top: 0;
}

.programOverview h6 {
  color: #9A98A2;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 0px;
  letter-spacing: 0.5px;
  font-size: 12px;
}
.bulletSpacer {
  padding: 0 4px;
  color:#CBCACF;
  font-weight: 300;
}

/* Report list */

span.statusPositive {
  color: #0E8345;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 0px;
  letter-spacing: 0.5px;
  font-size: 12px;
  padding: 8px 12px;
  border-radius: 8px;
  background-color:#E7F3ED;
  min-width: 110px;
}

span.statusError {
  color: #830e0e;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 0px;
  letter-spacing: 0.5px;
  font-size: 12px;
  padding: 8px 12px;
  border-radius: 8px;
  background-color:#f3e7e7;
  width: 110px !important;

}

span.statusProgress {
  color: #0e4a83;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 0px;
  letter-spacing: 0.5px;
  font-size: 12px;
  padding: 8px 12px;
  border-radius: 8px;
  background-color:#e9e7f3;
  width: 110px !important;

}

/* Sidebar */

.topList {
  display: flex;
  min-height: calc(100%);
  flex-direction: column;
  margin-top:100px;
  color:#1E1E1E;
}

.bottomList {
  display: flex;
  min-height: calc(100%);
  flex-direction: column;
  color:#1E1E1E;
}

.MuiButtonBase-root {
  padding: 10 10 10 10 !important
}

.topList .routingIcon, .bottomList .routingIcon {
  color:#1E1E1E;
  font-size: 18px;
  width: 18px !important;
}

.topList .itemIcon, .bottomList .itemIcon {
  min-width: 18px;
  margin-right: 12px;
}

/* Reports */

.reportTile {
  padding: 24px !important;
  border-radius: 8px !important;
  margin-bottom: 20px;
}
